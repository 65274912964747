

.socialMedia:hover{
    color: #202528 !important;
    cursor: pointer;
}

.FooterSolicalMedia:hover{
  color: #0072BC !important;
  cursor: pointer;
}
.dropDownSer:hover{
    background-color: #0072BC !important;
    color: #FFFFFF !important;
}




@media screen and (max-width: 600px){
  h1{
  text-align:center;
  text-transform: uppercase;
  color: #F1FAEE; 
  font-size: 2.125rem;
}
.zoom{
  width:auto !important; 
}
.quoteMobile{
  display: none;
}
  .textmoniLine{
    display: none;
  }
  .roller{
  height: 2.6rem; 
  line-height: 2.125rem;  
  }
  
  #spare-time {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  
  .roller #rolltext {  
  animation: slide-mob 5s infinite;  
}
  
  @keyframes slide-mob {
  0%{
    top:0;
  }
  25%{
    top: -2.125rem;    
  }
  50%{
    top: -4.25rem;
  }
  72.5%{
    top: -6.375rem;
  }
}
}
/* CSS */
.button-64 {
  align-items: center;
  background-image: linear-gradient(144deg,#ff8a4a, #FC4243 50%,#ffb6b6);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-64:active,
.button-64:hover {
  outline: 0;
}

.button-64 span {
  background-color: rgb(5, 6, 45);
  padding: 8px 0px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-64:hover span {
  background: none;
}

.workProcessMobile{
  display: none !important;
}

@media (min-width: 768px) {
  .button-64 {
    /* font-size: 18px;
    min-width: 196px; */
    font-size: 14px;
    min-width: 150px;
  }
  .TopHeadMobile,.Mobile-Form{
    display: none;
  }

}

.skew-div {
  transform: skewX(-28deg);
}

@media only screen and (max-width: 768px) {


  .FormImages.ContactImageTouch{
    display: none;
  }

  .FaqImage{
    display: none;
  }
 .TopHeadDesktop,.display-Form{
  display: none;
 }
 .Banner{
  height: 195px !important;
  background-position: 72% 0% !important;
  position: relative;
 }
 h1.react-reveal {
  font-size: 20px;
  position: relative;
  top: 20px;
 }
 
 h5.react-reveal {
  font-size: 15px;
 }
 img.react-reveal{
  position: absolute;
    right: 0;
    /* bottom: 0; */
    top: 17%;
 }
 .Mobile-Form{
  display:block;
 }
 .Banner-title{
  margin: 30px 0 !important;
 }
 .TopHeadMobile img{
  width: 40px;
 }
 .socialMedia{
   color: #202528 !important;
 }

 .TopHeadNavbar{
  background-color: #E1E1E0 !important;
 }

 .is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

 .TopHeadMobile{
  width:'100%';
 }
 .workProcess{
  display: none !important;
 }
 .workProcessMobile{
  display: block !important;
}
.AboutImageColoum{
  display: none;
}

.TestmoniCol{
  display: none;
}


}

.AboutImage{
  box-shadow: #0F1D64 5px 5px, #0926A9 10px 10px;
}

/* App.css */

.App {
  text-align: center;
}

.counter {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ServiceBanner{
  min-height: 700px;
  background: linear-gradient(0deg, #002147, #002147db), url(https://img.freepik.com/free-photo/full-shot-delivery-man-carrying-giant-present_23-2150262202.jpg?w=740&t=st=1706083355~exp=1706083955~hmac=75acf6c…);
  /* background: linear-gradient(0deg, #ff474780, #ff00148c), url(https://img.freepik.com/free-photo/full-shot-delivery-man-carrying-giant-present_23-2150262202.jpg?w=740&t=st=1706083355~exp=1706083955~hmac=75acf6c…); */
  background-size: cover;
}
.marquee-container {
  overflow: hidden;
}

.marquee {
  display: flex;
  animation: marqueeScroll 60s linear infinite; 
}

.marquee img {
  flex-shrink: 0;
  filter: grayscale(100%);
}

@keyframes marqueeScroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.heart {
	animation: beat .95s infinite alternate;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  animation-duration: 0.7s;
  animation-name: fadeInDown;
  animation-timing-function: ease-out;
}
/* Heart beat animation */
@keyframes beat{
	to { transform: scale(1.4); }
}

.zoom:hover {
  transform: scale(1.1); 
}
.zoomSer:hover {
  transform: scale(1.1); 
  border-radius: 20px;
  border: 1px solid #ffff;
  background-color: #F9EBED;
}

.atagServ:hover{
  color: #FC4243;
}

.headerMain{
  z-index: 9999 !important;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 30px 20px 30px;
  color: #23262B;
  background-color: #F8F6F2;
  border-top: 1px solid #e5e5e5;
}

.footer > * {
  flex:  1 100%;
}

.footer__addr {
  margin-right: 1.25em;
  margin-bottom: 2em;
}

.footer__logo {
  font-family: 'Pacifico', cursive;
  font-weight: 400;
  text-transform: lowercase;
  font-size: 1.5rem;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

.nav__title {
  /* font-weight: 400; */
  font-size:15px;
}

.footer address {
  font-style: normal;
  color: #23262B;
}

.footer__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.07);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
  flex-flow: row wrap;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: #23262B;
}

.nav__ul--extra {
  column-count: 2;
  column-gap: 1.25em;
}

.legal {
  display: flex;
  flex-wrap: wrap;
  color: #23262B;
}
  
.legal__links {
  display: flex;
  align-items: center;
}

.heart {
  color: #2f2f2f;
}

@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1;
  }
  
  .nav__item--extra {
    flex-grow: 2;
  }
  
  .footer__addr {
    flex: 1 0px;
  }
  
  .footer__nav {
    flex: 2 0px;
  }
  .HeaderMenu2{
    margin: 0 15%;
  }
}

/* .propertyCircle:hover{
  background-color: #FC4243 !important;
  cursor: pointer;
  color: #FFFFFF;
}
.propertyCircleIcon:hover{
  color: #ffff;
} */

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blinking {
  animation: blink 1s infinite;
}


.FooterButtonGetq:hover{
  background-color: #000;
}

.contactUsCircle:hover{
  background-color: #111b51;
  color: #e5e5e5;
}



/* NavbarComponent.css */

.servceNavbar {
  display: block !important;
}

.servceNavbarResponsive {
  display: none !important;
}


@media only screen and (max-width: 600px) {
  .servceNavbar {
    display: none !important;
  }
  .servceNavbarResponsive {
    display: block !important;
  }
  .header2set {
    display: none !important;
  }
  .logoinResponse{
    width: 100px !important; 
  }
}


/* CSS */
.button-70 {
  background-image: linear-gradient(#ff5959, #E01419);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-71 {
  background-image: linear-gradient(#ff5959, #E01419);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 10px 2px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  width: 150px;
  touch-action: manipulation;
}










.topHoverHead {
  color: #18272F;
  position: relative;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 9px !important;
}

.topHoverHead::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: #F00A21;
  /* background-color: #0E1852; */
  top: 0;
  margin-bottom: 0px;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.topHoverHead:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}





/* ----------- TEST -----------  */


.list {
  position: relative;
}
.list h2 {
  color: #393939;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.list ul {
  position: relative;
}
.list ul li {
  position: relative;
  left: 0;
  color: #393939;
  list-style: none;
  margin: 4px 0;
  border-left: 2px solid #FC4243;
  transition: 0.5s;
  cursor: pointer;
}
/* .list ul li:hover {
  left: 10px;
}
.list ul li span {
  position: relative;
  padding: 8px;
  padding-left: 12px;
  display: inline-block;
  z-index: 1;
  transition: 0.5s;
}
.list ul li:hover span {
  color: #111;
}
.list ul li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #FC4243;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.5s;
}
.list ul li:hover:before {
  transform: scaleX(1);
} */






#navbar {
  display: grid;
  min-height: 80px;
  grid-auto-flow: column;
  background-color: #fcfcfc;
  grid-gap: 1.5em;
  padding: 0 20px;
  -webkit-box-shadow: -1px 4px 5px 0px rgba(209, 205, 209, 1);
  -moz-box-shadow: -1px 4px 5px 0px rgba(209, 205, 209, 1);
  box-shadow: -1px 4px 5px 0px rgba(209, 205, 209, 1);
}




/* =======================
******* Mobile Menu ******
========================*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #282828;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #fff;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

a {
  font-family: "Open Sans", sans-serif;
}

/* Mobile to Tablet  */
@media (min-width: 320px) and (max-width: 767px) {
  #links,
  .mobile-menu {
    display: none;
  }
  #logo {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-gap: 100px;
 }
}

/* Tablet to Desktop */
@media (min-width: 768px) {
  .mobile-btn {
    display: none;
  }
}
