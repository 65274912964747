
.h11 {
  text-align: center;
  font-weight: 300;
  color: #777
}

.h11,.sspan {
  font-weight: 600;
}

.conatinerCSS {
  width: 80%;
  padding: 50px 0;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
}

.conatinerCSS:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #CCD1D9;
  z-index: 1
}

.timeline-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #F5F7FA;
  background: #FC4243;
  margin-top: 10px;
  z-index: 9999
}

.timeline-content {
  width: 95%;
  padding: 0 15px;
  color: #666
}

.timeline-content h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 500
}

.timeline-content span {
  font-size: 15px;
  color: #a4a4a4;
}

.timeline-content p {
  font-size: 14px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}


@media screen and (max-width: 768px) {
  .conatinerCSS:before {
     left: 8px;
     width: 2px;
  }
  .timeline-block {
     width: 100%;
     margin-bottom: 30px;
  }
  .timeline-block-right {
     float: none;
  }

  .timeline-block-left {
     float: none;
     direction: ltr;
  }
}

/* .roller #rolltext {
  position: absolute;
  top: 0;
  animation: slide 10s infinite;  
}  */


.prr {
  text-shadow: 0 0 7px rgba(255,255,255,.3), 0 0 3px rgba(255,255,255,.3);
  display: flex;
}


.animation {
  height:50px;
  overflow:hidden;
  text-align: left;
}

.animation > div > div {
  padding: 0.25rem 0.75rem;
  height:3rem;
  margin-bottom: 2.9rem;
  display:inline-block;

}

.animation div:first-child {
  animation: text-animation 10s infinite;
}



@keyframes text-animation {
  0% {margin-top: 0;}
  10% {margin-top: 0;}
  20% {margin-top: -5.62rem;}
  30% {margin-top: -5.62rem;}
  40% {margin-top: -11.24rem;}
  60% {margin-top: -11.24rem;}
  70% {margin-top: -5.62rem;}
  80% {margin-top: -5.62rem;}
  90% {margin-top: 0;}
  100% {margin-top: 0;}
}

/* --------------------- */

/* Mixin */
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-250px * 7)); }
}

/* Styling */
.sliderL {
  background: white;
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.sliderL::before,
.sliderL::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.sliderL::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.sliderL::before {
  left: 0;
  top: 0;
}

.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slide2 {
  height: 100px;
  width: 250px;
}



.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
  background-color: #F1F1F1;
}
.accordion-button:not(.collapsed) {
  color: #000;
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #111b51;
}
.BannerReponse{
  display: none;
 }

 .propertyCircle{
  
 }

 .propertyCircle:hover{
  background-color: #00157A !important;
  cursor: pointer;
  color: #FFFFFF !important;
}
.propertyCircleIcon:hover{
  color: #ffff;
}

/* .TrackImge{
  width: 350px !important;
} */

 @media only screen and (min-width: 768px){
  .TestCard{
    width: 33rem !important;
  }
 }

 .mobileViewCoutAbout{
  display: none;
 }
 
 .formBoxsh{
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 }

@media only screen and (max-width: 768px) {

  .bannerHeading{
    font-size: 20px;
  }

  .propertyCircle2,.propertyCircle3{
    width:106px !important;
    height:106px !important;
    box-shadow: none !important;
  }
  .FormFinalInpu{
    margin-top:10px;
  }
  .FormFinalInpu2{
    padding-top: 0px !important;
  }
  .calenderHeight{
    margin-top: 100px !important;
  }
  .propertyCircle2 svg{
    font-size: 50px !important;
  }
  .selectPropBox{
    box-shadow: none !important;
  }
  .selectPropBox h5{
    font-size:17px !important;
  }
  .CommercialIcon{
    font-size: 200px !important;
  }

  .finalFormButton{
    margin-bottom: 20px;
  }

  .weAlsoProvide{
    display: none;
  }

   .hrLine,.serviceFaq{
      display: none;
   }
   .carouselClass{
    margin: 35px 0;
   }
   .FooterLast{
    text-align: center;
   }
   .mobileViewTestmoialtext{
    display: none;
   }
   .formBoxsh {
    box-shadow: none; 
  }

   
   /* .CalenderFullView{
    display: none;
   } */
   /* .CalenderMobileView{
    display: block;
   } */
   /* .css-17vsgdu-MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none !important;
} */
   .FormImages{
    display: none;
   }
   .Banner{
    display: none;
   }
   .BannerReponse{
    display: block !important;
   }
   .bannerHeading{
    margin-left: 0px !important;
   }
   .TrackImge{
    display: none;
   }
   .AboutCount{
    font-size: xx-large;
    padding-top: 10px;
   }
   .roller #rolltext {  
    animation: slide-mob 10s infinite;  
  }
   .AboutImage2{
    display: none;
   }
   .bgServiceImage{
    height: 477px !important;
   }
   .Contactcard{
    margin-left: 0px !important;
    margin-right: 0px !;
   }
   .CircleText{
    text-align: center;
    font-size: 'revert-layer';
   }
   .zoom1{
    margin: 20px 0px;
   }
   .aboutCountLine{
    display: none;
   }
   .webViewCoutAbout{
    display: none;
   }
   .mobileViewCoutAbout{
    display: block;
   }
   .ContactImageTouch{
    display: none;
   }
   .serviceTitle{
    margin-left: 25px !important;
   }
   .serviceSubTitle{
    margin-left: 30% !important;
   }
   .FQASerivice{
    padding-top: 0px !important;
   }
   .testimonialsCard{
    padding-top: 10px;
   }
   .FooterTopSet{
    display: none !important;
   }
   .testimonialsNumberSer{
    padding-left: 20px !important;
   }
   .testimonialsNumberSer2{
    padding-left: 8px !important;
   }
   .getQuoteBtn{
    display: none;
   }
   
}

  .success-animation {
     margin:90px auto;
    }

  .checkmark {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #4bb71b;
      stroke-miterlimit: 10;
      box-shadow: inset 0px 0px 0px #4bb71b;
      animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
      position:relative;
      top: 5px;
      right: 5px;
     margin: 0 auto;
  }
  .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #4bb71b;
      fill: #fff;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
   
  }
  
  .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
      100% {
          stroke-dashoffset: 0;
      }
  }
  
  @keyframes scale {
      0%, 100% {
          transform: none;
      }
  
      50% {
          transform: scale3d(1.1, 1.1, 1);
      }
  }
  
  @keyframes fill {
      100% {
          box-shadow: inset 0px 0px 0px 30px #4bb71b;
      }
  }














 .main{
   height:100vh;
   width:100%;  
   display:flex;
   align-items:center;
   justify-content:center;
   text-align:center;
 }
 .heading1{
   color: #40ff00; 
   font-size: 4rem;
 }
 
 .roller{
   height: 4.125rem;
   line-height: 4rem;
   position: relative;
   overflow: hidden; 
   width: 100%;
   justify-content: center;
   align-items: center;
   text-align: left;
 }
 
 
 #spare-time{
   font-size: 1rem;
   font-style: italic;
   letter-spacing: 1rem;
   margin-top: 0;
   color: #A8DADC;
   
 }
 
 /* .roller #rolltext {
   position: absolute;
   top: 0;
   animation: slide 5s infinite;  
 } */
 
 @keyframes slide {
   0%{
     top:0;
   }
   25%{
     top: -4rem;    
   }
   50%{
     top: -8rem;
   }
   72.5%{
     top: -12.25rem;
   }
 }

 .hrService{
  border: 2px solid #0A249D;
  border-radius: 5px;
  width: 3%;
  margin-bottom: 1%;
 }


/* .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  color: #ffff !important;
  background-color:#FC4243 !important;
} */


/* .css-4jnixx-MuiStack-root>.MuiTextField-root {
  min-width: 100% !important;
} */
.css-4jnixx-MuiStack-root {
  padding-top: 8px !important;
}

/* .css-14prqje-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar,.css-1wzt60o-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  width: 1px;
} */

/* .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected {
  background-color: #FC4243 !important;
  color: #fff;
} */

/* .css-hip9hq-MuiPaper-root-MuiAppBar-root{
  background-color: #FC4243 !important;
  border-radius: 50px;
} */


.iconHover:hover{
  color: #F50202;
  border: 1px solid #ff6a6a;
  border-radius: 30%;
}

 /* @import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Inter&display=swap'); */

 @import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Inter&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


.image-circle {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  border-top: 2px dashed #FC4243;
  border-right: 2px dashed #FC4243;
  border-left: 2px dashed #000000;
  border-bottom: 2px dashed #000000;
  padding: 10px;
  animation: spin 10s infinite linear;
}
.image-circle .imgesr {
  animation: spin 10s infinite reverse linear;
  width: 100%;
  border-radius: 50%;
}

@keyframes spin {
  100% {
      transform: rotate(1turn);
  }
}


.button-21 {
  align-items: center;
  appearance: none;
  background-color: #3EB2FD;
  background-image: linear-gradient(1deg, #393939, #262E3E 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-21:active,
.button-21:focus {
  outline: none;
}

.button-21:hover {
  background-position: -20px -20px;
}

.button-21:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}




.button-70 {
  /* background-image: linear-gradient(#ff5555, #9f0000); */
  background-image: linear-gradient(#0827b7, #111b51);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  /* margin-top: 5px !important; */
  margin: 5px 0px 0px 0px;
  padding: 10px 2px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100px;
}



.btnGetQuote {
  display: flex;
  position: relative;
  width: 100px;
  height: 100px;
  background: linear-gradient(to bottom, #0827b7 0%, #111b51 100%);
  /* background: linear-gradient(to bottom, #FF7878 0%, #FC4243 100%); */
  box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  text-decoration: none;
  color: white;
}

.btnGetQuote::before {
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  height: 100%;
  border-radius: 50%;
  background: black;
  opacity: 0;
  content: '';
  animation: pulse 1s infinite;
  top: 2px;
}

.btnGetQuote:hover::before {
  animation: none;
  opacity: .4;
  transform: scale(1.3);
}

.btnGetQuote.is-clicked {
  background: linear-gradient(to bottom, gray 0%, dimgray 100%);
}

.btnGetQuote.is-clicked:before {
  animation: blastOut 1s;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: .4;
  }
  to {
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes blastOut {
  from {
    transform: scale(0.9);
    opacity: .4;
  }
  to {
    transform: scale(10);
    opacity: 0;
  }
}

.callContact:hover{
  color: #6f0000;
  /* -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.1);  */
}



/* /////////////////////////////////////////////////////////// */

.makemyimage:hover{
  transform: scale(1.2);

}
.makemyimage{
  transition: transform 0.3s ease-in-out;

}

.fixed-height-card {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fixed-height-card .MuiCardContent-root {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogcardHeader:hover{
  color: #FE6766;
}


.responsive-section {
  background: linear-gradient(to top, #ffff 0%, #edf4fe 74%, #ffe7e5 100%);
  position: relative;
  padding: 60px 0 80px;
  overflow: hidden;
}

.responsive-heading {
  font-weight: 600;
  color: #171717;
  font-size: 53px;
  line-height: 1.16em;
  margin-bottom: 25px;
  word-wrap: break-word;
  font-family: 'Proxima Nova, 'sans-serif'' !important; 
} 

.responsive-image {
  width: 100%;
  height: auto;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .responsive-heading {
    font-size: 45px;
    text-align: start;
    text-transform: uppercase;
  }
}

@media (max-width: 992px) {
  .responsive-heading {
    font-size: 38px;
  }
}

@media (max-width: 768px) {
  .responsive-heading {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .responsive-heading {
    font-size: 28px;
  }
}



.teams-list {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #444;
  margin-left: 20px;
  padding: 0;
}

.teams-list li {
  margin-bottom: 10px;
  list-style-type: disc;
}

.teams-heading {
  font-size: 24px;
  color: #333;
  margin-top: 40px;
}

.teams-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .teams-heading {
    font-size: 20px;
  }

  .teams-list {
    font-size: 14px;
    line-height: 22px;
  }
}
